<template>
	<main class="main-page">
		<template v-if="showHeader">
			<section class="page-section">
				<div class="container">
					<div class="grid mb-3 card nice-shadow-6 justify-content-between align-items-center">
						<div class="col ">
							<div class="">
								<div class="flex align-items-center ">
									<div class="mr-3">
										<Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
											class="p-button-sm p-button-rounded px-4" />
									</div>
									<div>
										<div class="text-2xl text-primary font-bold">My Notifications</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="col comp-grid">
						<div class="flex align-items-center justify-content-around">
							<div v-if="searchText" :class="filterTagClass">
								Search
								<Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}
								</Chip>
							</div>
						</div>
						<div>
							<template v-if="showBreadcrumbs && $route.query.tag">
								<Breadcrumb :home="{icon: 'pi pi-home', to: '/notifications'}"
									:model="pageBreadCrumb" />
							</template>
							<!-- Master Page Start -->
							<div class="page-records" v-if="!loading">
								<div class="grid grid-nogutter">
									<template v-for="(data, i) of records" :key="i">
										<div class="col-12">
											<div class="card mb-2 p-2">
												<div class="grid justify-content-between">
													<div class="col flex gap-5 align-items-center">
														<div class="p-4">
															<div class="text-sm text-400">{{
																$utils.relativeDate(data.date_created) }} </div>
															<div class="text-primary font-bold text-lg mt-2">{{
																$utils.upper(data.title) }}</div>
															<div class="text-md" lines="10">{{ data.message }}
															</div>
														</div>
													</div>
													<div class="col-fixed text-right" style="width:auto">
														<div class="flex">
															<Menubar class="p-button p-button-text p-button-sm"
																:model="getActionMenuModel(data)">
															</Menubar>
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
								</div>
							</div>
							<!-- page loading indicator -->
							<template v-if="loading">
								<div>
									<table class="p-datatable-table w-full">
										<tbody>
											<tr v-for="n in 5" :key="n">
												<td class="text-left" style="width:100%">
													<Skeleton height="5rem" class="m-2" />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</template>
							<!-- end of page loading indicator-->
							<!-- Empty record -->
							<template v-if="pageReady && !records.length">
								<div class="p-3 my-3 text-500 text-lg font-medium text-center">
									No record found
								</div>
							</template>
							<!-- end of empty record-->
							<!-- pagination component-->
							<template v-if="showFooter">
								<div class="">
									<div class="flex justify-content-between">
										<div class="flex-grow-0">
											<div class="flex">
												<div v-if="selectedItems.length" class="m-2">
													<Button @click="deleteItem(selectedItems)" icon="pi pi-trash"
														class="p-button-danger" title="Delete Selected" />
												</div>
											</div>
										</div>
										<div class="flex-grow-1">
											<div v-if="pageReady && totalPages > 1"
												class="flex  justify-content-center">
												<div class="m-2">
													<Button :disabled="pagination.page <= 1" label="Previous"
														@click="loadPreviousPage()" icon="pi pi-arrow-left" />
												</div>
												<div class="m-2">
													<Button :disabled="pagination.page >= totalPages" label="Next"
														@click="loadNextPage()" icon="pi pi-arrow-right"
														icon-pos="right" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
							<!-- end of pagination component-->
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script setup>
	import { reactive, toRefs, onMounted } from 'vue';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useListPage } from '@/composables/listpage.js';
	const props = defineProps({
		primaryKey: {
			type: String,
			default: 'notification_id',
		},
		pageName: {
			type: String,
			default: 'notifications',
		},
		routeName: {
			type: String,
			default: 'notificationsactive',
		},
		apiPath: {
			type: String,
			default: 'notifications/active',
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: true,
		},
		emptyRecordMsg: {
			type: String,
			default: "No notification found",
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete notification",
		},
		msgBeforeDelete: {
			type: String,
			default: "Are you sure you want to delete this notification?",
		},
		msgAfterDelete: {
			type: String,
			default: "Notification deleted successfully",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 15,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: '', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	const app = useApp();
	const filters = reactive({
	});
	// init list page hook
	const page = useListPage(props, filters);
	const { state, methodsProps } = page;
	const {
		totalRecords,
		recordCount,
		loading,
		selectedItems,
		pagination,
		searchText,
		pageReady
	} = toRefs(page.state);
	const {
		records,
		apiUrl,
		currentRecord,
		pageBreadCrumb,
		canLoadMore,
		finishedLoading,
		totalPages,
		recordsPosition
	} = page.computedProps;
	const {
		load,
		reload,
		loadNextPage,
		loadPreviousPage,
		exportPage,
		clearSearch,
		onSort,
		deleteItem,
		debounce,
		setCurrentRecord,
		isCurrentRecord,
		removeFilter,
		getFilterLabel,
		filterHasValue,
		importComplete
	} = page.methods;
	function getActionMenuModel(data) {
		return [
			{
				label: "Delete",
				command: (event) => { deleteItem(data.notification_id) },
				icon: "pi pi-trash"
			}
		]
	}
	onMounted(() => {
		const pageTitle = "Notifications";
		app.setPageTitle(props.routeName, pageTitle);
		load();
	});
</script>
<style scoped>
</style>